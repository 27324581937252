import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="content">
          <div className="container">
            <div id className="row stageless">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h1>Impressum</h1>
                <p><strong>Kenvue</strong><br />
Johnson & Johnson GmbH<br />
Johnson & Johnson Platz 2<br />
D-41470 Neuss</p>

<p>Telefon: +49 (0) 2137 / 9 36 - 0<br />
Fax: +49 (0) 2137 / 9 36 - 2333</p>

<p><a href="https://www.jnjgermany.de/" target="_blank">www.jnjgermany.de</a><br />
<a href="https://www.kenvue.com/" target="_blank">www.kenvue.com</a></p>

<p>Reg.-Ger. Amtsgericht Neuss HRB 14980</p>

<p>WEEE-Reg.-Nr. DE24212257<br />
Ust.-ID-Nr.: DE 11 93 60 841</p>

<p>Vorsitzender des Aufsichtsrates: Joyce ter Heerdt<br />
Geschäftsführer(in): Victor Geus (Vorsitzender), Stefanie Rivera, Fredrik Hedvall</p>
                <p
                  style={{
                    "line-height": "1.42857"
                  }}
                >
                  Die EU-Kommission hat eine Internetplattform zur
                  Online-Beilegung von Streitigkeiten (?OS-Plattform?) zwischen
                  Unternehmern und Verbrauchern eingerichtet. Die OS-Plattform
                  ist unter folgendem Link erreichbar:&nbsp;
                  <a
                    href="http://www.ec.europa.eu/consumers/odr"
                    target="_blank"
                    className="external-link-new-window"
                  >
                    www.ec.europa.eu/consumers/odr
                  </a>
                  . &nbsp;
                </p>
                <p
                  style={{
                    "line-height": "1.42857"
                  }}
                >
                  Hinweis zu § 36 VSBG: Wir nehmen an einem
                  Streitbeilegungsverfahren vor einer
                  Verbraucherschlichtungsstelle nicht teil und sind dazu auch
                  nicht verpflichtet.
                </p>
                {/* <p
                  style={{
                    "line-height": "1.42857"
                  }}
                >
                  bebe Zartpflege<sup>®</sup>&nbsp;ist im Vertrieb der Johnson
                  &amp; Johnson GmbH. Das Unternehmen ist die deutsche Tochter
                  des gleichnamigen globalen Konzerns. Mit rund 118.000
                  Mitarbeitern in mehr als 250 Firmen zählt Johnson &amp;
                  Johnson zu den weltweit größten Unternehmen der
                  Gesundheitsfürsorge.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;