/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="de" dir="ltr" />
          <body className="desktop" />
          <meta httpEquiv="content-type" content="text/html;charset=utf-8" />
          <base />
          <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="0191a674-3bf5-7a1a-9658-27fd15990472" />
          <link
            rel="shortcut icon"
            href="/favicon.html"
            type="image/gif; charset=binary"
          />
          <link
            rel="icon"
            href="/favicon.html"
            type="image/gif; charset=binary"
          />
          <title>bebe Zartpflege: Impressum</title>
          <meta
            name="keywords"
            content="bebe zartpflege,Johnson Johnson,Impressum,Pflegeprodukte,Produkte,Kontakt"
          />
          <meta
            name="description"
            content="Hier findest du das Impressum von bebe-zartpflege.de mit rechtlichen Informationen der Johnson & Johnson GmbH mit Sitz in Neuss, Nordrhein-Westfalen &#9654; Jetzt online ansehen und informieren!"
          />
          <meta name="language" content="de" />
          <meta name="revisit-after" content="10 days" />
          <meta name="robots" content="index, follow" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/typo3temp/stylesheet.css?1400849731"
            media="all"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/fileadmin/css/style.css?1551437847"
            media="all"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/fileadmin/css/tmpfa75.css?1398684602"
            media="all"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/fileadmin/css/zartpflege0ba1.css?1430732196"
            media="all"
          />
          
          <script
            type="text/javascript"
            innerHTML="
function OptanonWrapper() { }
"
          />
          <script
            src="/typo3temp/javascript.js?1400849731"
            type="text/javascript"
          />
         <script innerHTML='digitalData = [{"event":"Page Loaded","page":{"analyticsId":"UA-91649088-10",
                    "brandName":"Bebe",
                    "country":"de",
                    "flattened":true,
                    "hostname":"www.bebe-zartpflege.de",
                    "language":"de",
                    "siteTier":"low",
              }}];'  
                />
          <script
            innerHTML="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','globalDataLayer','GTM-PJSTS9Z');"
          />
          <meta
            name="google-site-verification"
            content="SYdH60iuiFnCSasHS4x-uSxTduJM8VvZ4xRGvWZKzB8"
          />
          <link rel="icon" href="/fileadmin/images/_layout/favicon.ico" />
          <link
            rel="apple-touch-icon"
            href="/fileadmin/images/_layout/favicon.png"
          />
        </Helmet>
        ;
      </React.Fragment>
    );
  }
}
